/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import * as S from './estilos';


import IconLogo from '../../Images/LogoDaniel.png';

function Header(props: any) {
  const history = useHistory();
  const [menu, setMenu] = useState(0);

  useEffect(() => {
    const hash = props.history.location.hash;
    if (hash && document.getElementById(hash.substr(1))) {
      // Check if there is a hash and if an element with that id exists
      document.getElementById(hash.substr(1))?.scrollIntoView({ behavior: 'smooth' });
    }
    // }, []);
  }, [props.history.location.hash]);

  return (
    <>
      <S.BgHeader id='Home'>
        <S.OutBgHeader>
          <S.OutHeader>
            <S.TextHeader href='#Home'>Home</S.TextHeader>
            <S.TextHeader href='#Shows'>Shows Corporativos</S.TextHeader>
            <S.TextHeader href='#Parceiros'>Marcas Parceiras</S.TextHeader>
            <S.TextHeader href='#Comerciais'>Comerciais de TV</S.TextHeader>
            <S.TextHeader href='#Internet'>internet</S.TextHeader>
            <S.TextHeader href='#Contato'>Contato</S.TextHeader>
          </S.OutHeader>

          <S.OutMenuMobile onClick={() => setMenu(1)}>
            <S.ButtonMenuMobile>
              <S.RiskMenuMobile />
              <S.RiskMenuMobile />
              <S.RiskMenuMobile />
            </S.ButtonMenuMobile>
            <S.TextMenuMobile>Menu</S.TextMenuMobile>
          </S.OutMenuMobile>
        </S.OutBgHeader>
      </S.BgHeader>

      {menu == 1 ? (
          <S.BgMenuMobile>
            <S.OutMenuMobile2>
              <S.OutLogoMobile><img src ={IconLogo} /></S.OutLogoMobile>
              <S.TextXMobile onClick={() => setMenu(0)}>X</S.TextXMobile>
              <S.ContentMenuMobile>
                <S.TextMenuMobile1 href='#Home' onClick={() => setMenu(0)}>Home</S.TextMenuMobile1>
                <S.TextMenuMobile1 href='#Shows' onClick={() => setMenu(0)}>Shows Corporativos</S.TextMenuMobile1>
                <S.TextMenuMobile1 href='#Parceiros' onClick={() => setMenu(0)}>Marcas Parceiras</S.TextMenuMobile1>
                <S.TextMenuMobile1 href='#Comerciais' onClick={() => setMenu(0)}>Comerciais de TV</S.TextMenuMobile1>
                <S.TextMenuMobile1 href='#Internet' onClick={() => setMenu(0)}>internet</S.TextMenuMobile1>
                <S.TextMenuMobile1 href='#Contato' onClick={() => setMenu(0)}>Contato</S.TextMenuMobile1>
              </S.ContentMenuMobile>
            </S.OutMenuMobile2>
          </S.BgMenuMobile>
        ) : (
         <></>
       )}
    </>
  );
}

export default withRouter(Header);
