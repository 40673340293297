import React, { useEffect, useState } from "react";

import { Header, Footer, Shows, TakeShow, Partners, Charismatic, Internet, Career, Contact } from "../../Componentes";
import "react-confirm-alert/src/react-confirm-alert.css";

function Home(props: any) {
  useEffect(() => {
    const hash = props.history.location.hash;
    if (hash && document.getElementById(hash.substr(1))) {
      // Check if there is a hash and if an element with that id exists
      document.getElementById(hash.substr(1))?.scrollIntoView({ behavior: "smooth" });
    }
    // }, []);
  }, [props.history.location.hash]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <link rel="stylesheet" href="fontes/stylesheet.css" />
      <Header />

      <Shows />

      <TakeShow />

      <Partners />

      <Charismatic />

      <Internet />

      <Career />

      <Contact />

      <Footer />
    </>
  );
}

export default Home;
