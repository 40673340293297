import React from 'react';
import { BrowserRouter, /* HashRouter, Router,*/ Route, Switch } from 'react-router-dom';
import Home from '../Pages/Home';
import { ThemeProvider } from 'styled-components';
import theme from '../Styles/theme';

const Router = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter basename='/'>
          <Switch>
            <Route path='/' exact component={Home} />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
};

export default Router;
