import styled from 'styled-components';
import ImgBgEvents from "../../Images/BgEvents.jpg";
import ImgBgCommercial from "../../Images/BgCommercial2.jpg";

export const OutImgCareer = styled.section`
    margin-top: 7vw;
`;



export const OutCommercial = styled.div`
    width: 1500px;
    margin: 0 auto;
    display: flex;
    margin-top: 14vw;

    @media (max-width: 1550px) {
      width: 90%;
    }
`;

export const OutContentCommercial = styled.div`
    width: 100%;
    background-image: url(${ImgBgCommercial});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 42px;
    height: 29vw;

    @media (max-width: 1060px) {
        height: auto;
        border-radius: 20px;
    }
`;

export const ContentCommercial = styled.div`
    width: 80%;
    margin: 0 auto;

    @media (max-width: 1550px) {
        width: 85%;

    }    
    
    @media (max-width: 1370px) {
        width: 90%;
        padding-left: 6%;
    }
    
    @media (max-width: 1060px) {
        width: 90%;
        padding-left: 0%;
    }
`;

export const OutImgBgCommercial = styled.div`
    margin-top: -61vw;
`;

export const TextImgCommercial1 = styled.div`
    font-weight: bold;
    font-size: 91px;
    text-transform: uppercase;
    text-align: center;
    padding-top: 17vw;

    @media (max-width: 1280px) {
        font-size: 70px;
        line-height: 60px;
    }

    @media (max-width: 860px) {
        font-size: 56px;
        line-height: 53px;
    }    
    
    @media (max-width: 650px) {
        font-size: 40px;
        line-height: 39px;
    }

    @media (max-width: 450px) {
        font-size: 32px;
        line-height: 24px;
    }
`;

export const TextImgCommercial2 = styled.div`
    font-weight: 300;
    font-size: 65px;
    text-transform: uppercase;
    text-align: center;

    @media (max-width: 1280px) {
        font-size: 52px;
    }    
    
    @media (max-width: 860px) {
        font-size: 44px;
    }

    @media (max-width: 650px) {
        font-size: 30px;
    }    
    
    @media (max-width: 450px) {
        font-size: 26px;
    }
`;

export const TextCommercial1 = styled.div`
    font-size: 75px;
    text-transform: uppercase;
    font-weight: bold;
    padding-top: 2vw;

    @media (max-width: 1370px) {
        font-size: 50px;
    }    
    
    @media (max-width: 500px) {
        font-size: 40px;
    }
`;

export const TextCommercial2 = styled.div`
    font-size: 18px;
    font-weight: 300;

    @media (max-width: 500px) {
        margin-top: 10px;
        margin-bottom: 10px;
    }
`;

export const OutItemsCommercial = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 95%;

    @media (max-width: 1550px) {
        width: 100%;
    }
`;

export const TextItemCommercial = styled.div`
    font-size: 18px;
    font-weight: bold;
    border: 2px solid #223a7d;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    border-radius: 44px;
    width: 31%;
    margin-top: 30px;
    cursor: pointer;

    @media (max-width: 1370px) {
        width: 32%;
        font-size: 15px;
        height: 40px;
        margin-top: 15px;
    }    
    
    @media (max-width: 950px) {
        width: 48%;
    }   
    
    @media (max-width: 600px) {
        width: 100%;
    }
`;

export const OutImgCommercial = styled.div`
    width: 310px;
    margin-right: -9vh;
    position: inherit;
    z-index: 2;

    @media (max-width: 1550px) {
        width: 23%;
        margin-right: -5vw;
    }   
    
    @media (max-width: 1060px) {
        display: none;
    }
`;







export const BgCommercial2 = styled.div`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background-color: #000522;
    display: flex;
    align-items: center;
    z-index: 100;
`;

export const XCommercial = styled.div`
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
`;

export const ContentCommercial2 = styled.div`
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @media (max-width: 1000px) {
        flex-direction: column;
    }

    @media (max-width: 500px) {
        width: 85%;
    }
`;

export const OutImgCinema = styled.div`
    width: 540px;
    margin: 0 auto;

    @media (max-width: 700px) {
        width: 100%;
    }
`;

export const LeftCommercial = styled.div`
    width: 32%;
    padding-top: 20px;

    @media (max-width: 1230px) {
        width: 40%;
    }    
    
    @media (max-width: 1000px) {
        width: 100%;
    }
`;

export const TextLeftCommercial1 = styled.div`
    font-weight: bold;
    font-size: 40px;
    line-height: 40px;

    @media (max-width: 500px) {
        font-size: 35px;
        line-height: 35px;
    }
`;

export const TextLeftCommercial2 = styled.div`
    font-weight: 300;
    font-size: 40px;
    line-height: 40px;
    color: #446fe7;

    @media (max-width: 500px) {
        font-size: 35px;
        line-height: 35px;
    }
`;

export const TextLeftCommercial3 = styled.div`
    font-size: 20px;
    font-weight: 500;
    margin-top: 30px;

    @media (max-width: 500px) {
        font-size: 18px;
        margin-top: 5px;
    }
`;







export const OutEvents = styled.section`
    width: 1600px;
    margin: 0 auto;
    margin-top: 50px;
    background-image: url(${ImgBgEvents});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 25px;

    @media (max-width: 1650px) {
        width: 90%;
    }    
    
    @media (max-width: 1330px) {
        width: 90%;
    }

    @media (max-width: 850px) {
        background-image: unset;
        width: 85%;
    }
`;

export const LeftEvents = styled.div`
    width: 60%;
    padding-left: 6%;
    padding-top: 4%;

    @media (max-width: 850px) {
        width: 100%;
        padding-left: 0%;
        padding-top: 2%;
    }
`;

export const OutImgEvents = styled.div`
    display: none;

    img {
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
    }

    @media (max-width: 850px) {
        display: block;
    }
`;

export const ContentEvents = styled.div`
    width: 60%;
    margin-top: -800px;
    margin-left: 5vw;
`;

export const TextEvents1 = styled.div`
    font-size: 75px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 75px;

    @media (max-width: 1650px) {
        width: 75%;
        font-size: 65px;
        line-height: 65px;
    }    
    
    @media (max-width: 1350px) {
        font-size: 53px;
        line-height: 52px;
    }

    @media (max-width: 850px) {
        font-size: 40px;
        line-height: 41px;
    }    
    
    @media (max-width: 500px) {
        font-size: 32px;
        line-height: 32px;
    }
`;

export const TextEvents2 = styled.div`
    font-size: 45px;
    text-transform: uppercase;
    font-weight: bold;
    color: #446fe7;
    margin-top: 4vh;
    margin-bottom: 1vh;

    @media (max-width: 1350px) {
        font-size: 37px;
        margin-top: 3vh;
    }    
    
    @media (max-width: 850px) {
        font-size: 32px;
        margin-top: 2vh;
    }
    
    @media (max-width: 500px) {
        font-size: 27px;
        margin-top: 1vh;
    }
`;

export const OutButtonsEvents = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;

    @media (max-width: 1350px) {
        margin-top: 15px;
    }

    @media (max-width: 600px) {
        flex-wrap: wrap;
    }

    @media (max-width: 850px) {
        margin-top: 0px;
    }
`;

export const ButtonEvent = styled.div`
    font-size: 17px;
    font-weight: 900;
    color: #446fe7;
    border: 2px solid #446fe7;
    width: 32%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    border-radius: 25px;
    cursor: pointer;

    @media (max-width: 1350px) {
        font-size: 14px;
        height: 40px;
    }    
    
    @media (max-width: 850px) {
        margin-top: 15px;
        width: 100%;
    }
`;