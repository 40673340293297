import styled from 'styled-components';
import ImgBgHeader from "../../Images/BgHeader.jpg";
import ImgBgHeaderResp from "../../Images/BgHeaderResp.jpg";

// export const BackgroundHeader = styled.header`
//   background-color: ${({ theme }) => theme.color.black};
//   background-color: ${({ theme }) => theme.color.primary};
//   position: fixed;
//   width: 100%;
//   z-index: 2;
// `;

export const BgHeader = styled.header`
    background-image: url(${ImgBgHeader});
    height: 60vw;
    background-position: center;
    background-size: cover;

    @media (max-width: 800px) {
        background-image: url(${ImgBgHeaderResp});
        height: 97vw;
    }
`;

export const OutBgHeader = styled.div`
    position: fixed;
    width: 100%;
    z-index: 99;
    background-color: rgba(14, 37, 100, .9);
    height: 110px;
    display: flex;
    align-items: center;

    @media (max-width: 1150px) {
        height: 90px;
    }  

    @media (max-width: 930px) {
        background-color: unset;
    }  
`;

export const OutHeader = styled.div`
    width: 1370px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1380px) {
        width: 1130px;
    }

    @media (max-width: 1150px) {
        width: 900px;
    }    
    
    @media (max-width: 930px) {
        display: none;
    }
`;


export const OutMenuMobile = styled.div`
    display: none;
    cursor: pointer;

    @media (max-width: 930px) {
        display: flex;
        position: absolute;
        top: 30px;
        left: 30px;
    }    
    
    @media (max-width: 500px) {
        top: 10px;
    }
`;

export const ButtonMenuMobile = styled.div`
    cursor: pointer;
`;

export const RiskMenuMobile = styled.div`
    width: 40px;
    height: 6px;
    background-color: #fff;
    margin-top: 8px;

    @media (max-width: 500px) {
        width: 35px;
        height: 4px;
        background-color: #fff;
        margin-top: 7px;
    }
`;

export const TextMenuMobile = styled.div`
    font-weight: 400;
    font-size: 30px;
    text-transform: uppercase;
    margin-left: 20px;
    margin-top: 3px;

    @media (max-width: 500px) {
        font-size: 23px;
        margin-left: 10px;
        margin-top: 3px;
    }
`;


export const TextHeader = styled.a`
    cursor: pointer;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    padding: 5px 20px;
    text-decoration: none;
    color: #fff;

    :hover {
        background-color: #fff;
        color: #0f2666;
        border-radius: 16px;
    }

    @media (max-width: 1380px) {
        font-size: 18px;
        padding: 5px 11px;
    }    
    
    @media (max-width: 1150px) {
        font-size: 16px;
        padding: 5px 6px;
    }
`;







export const BgMenuMobile = styled.div`
    background-color: #010623;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    top: 0px;
    left: 0px;
`;

export const OutMenuMobile2 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const OutLogoMobile = styled.div`
    width: 130px;
    margin-top: 18px;
`;

export const TextXMobile = styled.div`
    font-weight: bold;
    position: absolute;
    right: 35px;
    font-size: 30px;
    top: 15px;
    cursor: pointer;
`;

export const ContentMenuMobile = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
`;

export const TextMenuMobile1 = styled.a`
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid;
    text-align: center;
    padding-bottom: 15px;
    padding-top: 15px;
    color: #fff;
    text-decoration: none;
`;

