import styled from "styled-components";
import ImgBgTopPartners from "../../Images/BgTopPartners.jpg";

export const OutPartners = styled.section`
  padding-top: 180px;

  @media (max-width: 1280px) {
    padding-top: 100px;
  }
`;

export const TopPartners = styled.div`
  background-image: url(${ImgBgTopPartners});
  background-position: center;
  background-size: cover;
  height: 58vw;
  display: flex;
  flex-direction: column;

  /* @media (max-width: 1040px) {
        display: none;
    } */
`;

export const TextPartners1 = styled.div`
  font-weight: 300;
  font-size: 65px;
  text-transform: uppercase;
  text-align: center;

  @media (max-width: 1280px) {
    font-size: 52px;
  }

  @media (max-width: 860px) {
    font-size: 44px;
  }

  @media (max-width: 650px) {
    font-size: 30px;
  }

  @media (max-width: 450px) {
    font-size: 26px;
  }
`;

export const TextPartners2 = styled.div`
  font-weight: bold;
  font-size: 91px;
  text-transform: uppercase;
  text-align: center;
  padding-top: 17vw;

  @media (max-width: 1280px) {
    font-size: 70px;
    line-height: 60px;
  }

  @media (max-width: 860px) {
    font-size: 56px;
    line-height: 53px;
  }

  @media (max-width: 650px) {
    font-size: 40px;
    line-height: 39px;
  }

  @media (max-width: 450px) {
    font-size: 32px;
    line-height: 24px;
  }
`;

export const ContentPartners = styled.div`
  margin-top: -14vw;
`;

export const TextPartners3 = styled.div`
  font-weight: bold;
  font-size: 45px;
  color: #446fe7;
  text-align: center;
  margin-bottom: 45px;
  text-transform: uppercase;

  @media (max-width: 500px) {
    font-size: 36px;
    margin-bottom: 10px;
  }
`;

export const OutItemsPartner = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  margin: 0 auto;
`;

export const ItemPartner = styled.div`
  width: 15.5%;
  height: 13vw;
  background-color: #fff;
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-left: 1%;

  @media (max-width: 1280px) {
    height: 14vw;
  }

  @media (max-width: 800px) {
    height: 21vw;
    margin-top: 15px;
    width: 23%;
    margin-left: 1.7%;
  }

  @media (max-width: 500px) {
    height: 29vw;
    width: 30%;
    margin-left: 2%;
  }
`;

export const OutArtist = styled.div`
  width: 1600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 80px;

  @media (max-width: 1630px) {
    width: 90%;
  }

  @media (max-width: 650px) {
    padding-top: 45px;
  }
`;

export const ImageArtist = styled.div`
  @media (max-width: 650px) {
    display: none;
  }
`;

export const ImageArtistResp = styled.div`
  display: none;

  @media (max-width: 650px) {
    display: block;
  }
`;

export const ContentArtist = styled.div`
  width: 67%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: -785px;

  @media (max-width: 1630px) {
    margin-top: -45vw;
  }

  @media (max-width: 650px) {
    width: 85%;
    margin: 0 auto;
    margin-top: -20vw;
  }
`;

export const TextArtist1 = styled.div`
  font-weight: bold;
  font-size: 75px;
  width: 64%;
  padding-right: 10%;
  line-height: 72px;

  @media (max-width: 1630px) {
    font-size: 52px;
    line-height: 55px;
  }

  @media (max-width: 1350px) {
    font-size: 42px;
    line-height: 45px;
  }

  @media (max-width: 1150px) {
    font-size: 35px;
    line-height: 37px;
  }

  @media (max-width: 830px) {
    font-size: 27px;
    line-height: 29px;
    margin-bottom: 5px;
  }

  @media (max-width: 650px) {
    width: 100%;
    padding-right: 0%;
  }
`;

export const OutButtonArtist = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
  padding-right: 13%;
  flex-wrap: wrap;

  @media (max-width: 650px) {
    width: 100%;
    padding-right: 0%;
    margin: 0 auto;
  }
`;

export const ButtonArtist = styled.div`
  font-weight: bold;
  font-size: 17px;
  border: 2px solid #1b3478;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47%;
  border-radius: 44px;
  text-transform: uppercase;
  margin-top: 47px;
  cursor: pointer;

  @media (max-width: 1630px) {
    width: 48%;
    font-size: 15px;
    margin-top: 19px;
  }

  @media (max-width: 1350px) {
    width: 48%;
    font-size: 13px;
    margin-top: 19px;
    height: 42px;
  }

  @media (max-width: 1150px) {
    width: 100%;
  }

  @media (max-width: 830px) {
    margin-top: 10px;
  }
`;

export const ImageArtist2 = styled.div`
  width: 930px;
  margin-top: 55px;
  margin-right: 10%;

  img {
    border-radius: 22px;
  }

  @media (max-width: 1630px) {
    width: 61%;
    margin-top: 35px;
    margin-right: 8%;
  }

  @media (max-width: 830px) {
    margin-top: 20px;
  }

  @media (max-width: 650px) {
    width: 90%;
    margin: 0 auto;
    margin-top: 6%;
  }
`;

export const OutCommercial = styled.div`
  width: 1500px;
  margin: 0 auto;
  display: flex;
  padding-top: 80px;

  @media (max-width: 1560px) {
    width: 90%;
  }

  @media (max-width: 650px) {
    padding-top: 30px;
  }
`;

export const OutContentCommercial = styled.div`
  width: 100%;
`;

export const ContentCommercial = styled.div`
  width: 80%;
  margin: 0 auto;
  margin-top: -500px;

  @media (max-width: 1560px) {
    margin-top: -30vw;
  }

  @media (max-width: 850px) {
    margin-top: -33vw;
  }

  @media (max-width: 600px) {
    width: 85%;
  }
`;

export const OutImgBgCommercial = styled.div``;

export const TextCommercial1 = styled.div`
  font-size: 75px;
  text-transform: uppercase;
  font-weight: bold;

  @media (max-width: 1230px) {
    font-size: 55px;
  }

  @media (max-width: 720px) {
    font-size: 43px;
  }

  @media (max-width: 600px) {
    font-size: 35px;
  }

  @media (max-width: 430px) {
    font-size: 29px;
  }
`;

export const TextCommercial2 = styled.div`
  font-size: 18px;
  font-weight: 300;

  @media (max-width: 1200px) {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

export const OutItemsCommercial = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 1200px) {
    margin-bottom: 30px;
  }
`;

export const TextItemCommercial = styled.div`
  font-size: 18px;
  font-weight: bold;
  border: 2px solid #223a7d;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 44px;
  width: 31%;
  margin-top: 30px;
  cursor: pointer;

  @media (max-width: 1560px) {
    width: 32%;
    font-size: 15px;
  }

  @media (max-width: 1200px) {
    width: 48%;
    margin-top: 15px;
  }

  @media (max-width: 720px) {
    font-size: 13px;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 42px;
    margin-top: 10px;
  }
`;

export const OutImgCommercial = styled.div`
  width: 310px;
  margin-left: -110px;

  @media (max-width: 1300px) {
    width: 22%;
    margin-left: -7vw;
  }

  @media (max-width: 850px) {
    display: none;
  }
`;

export const BgCommercial2 = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: #000522;
  display: flex;
  align-items: center;
  z-index: 101;
`;

export const XCommercial = styled.div`
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
`;

export const ContentCommercial2 = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1000px) {
    flex-direction: column;
  }

  @media (max-width: 500px) {
    width: 85%;
  }
`;

export const LeftCommercial = styled.div`
  width: 29%;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const TextLeftCommercial1 = styled.div`
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;

  @media (max-width: 500px) {
    font-size: 35px;
    line-height: 35px;
  }
`;

export const TextLeftCommercial2 = styled.div`
  font-weight: 300;
  font-size: 40px;
  line-height: 40px;
  color: #446fe7;

  @media (max-width: 500px) {
    font-size: 35px;
    line-height: 35px;
  }
`;

export const TextLeftCommercial3 = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-top: 30px;

  @media (max-width: 500px) {
    font-size: 18px;
    margin-top: 5px;
  }
`;

export const OutItemsShows = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 65%;

  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 30px;
  }
`;

export const ItemShows = styled.div`
  display: flex !important;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const LeftItemShows = styled.div`
  width: 30%;

  @media (max-width: 1620px) {
    height: 33vw;
    overflow: hidden scroll;
    padding-right: 30px;
    color: rgb(255, 255, 255);
  }

  @media (max-width: 900px) {
    width: 95%;
    overflow: hidden scroll;
    padding-right: 25px;
  }

  @media (max-width: 600px) {
    width: 90%;
    padding-right: 10px;
    height: 300px;
  }
`;

export const TextItemShows1 = styled.div`
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 20px;
  color: #446fe7;
`;

export const TextItemShows2 = styled.div``;

export const RightItemFotos = styled.div`
  height: 33vw;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 1000px) {
    height: 45vw;
    width: 100%;
  }

  @media (max-width: 450px) {
    height: 80vw;
  }
`;
