import styled from 'styled-components';
// import BgRodape from "../../Images/BgRodape.jpg";

export const OutPartner = styled.footer`
  width: 1170px;
  margin: 0 auto;
  padding-top: 70px;
  padding-bottom: 70px;

  @media (max-width: 1235px) {
    width: 860px;
    padding-top: 35px;
    padding-bottom: 35px;
  }

  @media (max-width: 930px) {
    width: 90%;
  }

  @media (max-width: 410px) {
    padding-top: 20px;
  }
`;

export const TopPartner = styled.div`
  width: 650px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 720px) {
    width: 100%;
  }
`;

export const RiskPartner = styled.div`
  width: 230px;
  height: 2px;
  background-color: #112252;

  @media (max-width: 720px) {
    width: 30%;
  }
`;

export const TextPartner = styled.div`
  font-weight: 400;
  font-size: 20px;
  text-transform: uppercase;

  @media (max-width: 410px) {
    font-size: 18px;
  }
`;

export const ContentPartner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;

  @media (max-width: 930px) {
    flex-wrap: wrap;
    margin-top: 35px;
  }

  @media (max-width: 600px) {
    margin-top: 15px;
  }
`;

export const ContentPartnerResp = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;

  @media (max-width: 930px) {
    flex-wrap: wrap;
  }

  @media (max-width: 530px) {
    display: flex;
    margin-top: 10px;
  }
`;

export const OutItensContentPartner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 930px) {
    width: 100%;
    flex-wrap: wrap;
  }

  @media (max-width: 530px) {
    margin-top: 10px;
  }
`;

export const OutItensContentPartner2 = styled.div`
  width: 47%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 930px) {
    width: 100%;
  }
`;

export const OutImgPartner = styled.div`
  width: 14%;
  cursor: pointer;

  @media (max-width: 930px) {
    width: 26%;
  }

  @media (max-width: 500px) {
    width: 40%;
  }
`;

export const OutLab = styled.div`
  background-color: #0f1013;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 930px) {
    height: 80px;
  }
`;

export const OutImgLab = styled.div``;
