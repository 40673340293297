import React, { useEffect, useState } from "react";
import * as S from "./estilos";
import { Oval } from "react-loader-spinner";
function Load(props: any) {
  return (
    // eslint-disable-next-line react/prop-types
    props.status && (
      <>
        <S.ForaSpiner>
          <Oval
            // type='Puff'
            color="#d69c49"
            height={100}
            width={100}
            secondaryColor="#446fe7"
            // timeout={3000} //3 secs
          />
        </S.ForaSpiner>
      </>
    )
  );
}

export default Load;
