import styled from "styled-components";
// import ImgBgTopPartners from "../../Images/BgTopPartners.jpg";

export const OutImgCharismatic = styled.section`
    margin-bottom: -19vw;
`;

export const OutMark = styled.div`
  width: 1600px;
  margin: 0 auto;
  margin-top: -18vw;


  @media (max-width: 1630px) {
    width: 90%;
  }
`;

export const OutImgMark = styled.div`
  @media (max-width: 900px) {
    display: none;
  }
`;

export const OutImgMarkResp = styled.div`
  display: none;

  @media (max-width: 900px) {
    display: block;
  }
`;

export const OutContentMark = styled.div`
  width: 44%;
  margin-top: -700px;
  padding-left: 14vh;

  @media (max-width: 1630px) {
    margin-top: -39vw;
    padding-left: 7%;
  }  
  
  @media (max-width: 900px) {
    padding-left: 0%;
    width: 85%;
    margin: 0 auto;
    margin-top: -15vw;
  }

  @media (max-width: 485px) {
    margin-top: -17vw;
  }
`;

export const TextMark1 = styled.div`
  font-weight: bold;
  font-size: 75px;
  text-transform: uppercase;
  line-height: 75px;
  width: 100%;

  @media (max-width: 1480px) {
    line-height: 60px;
    font-size: 60px;
  }

  @media (max-width: 1170px) {
    line-height: 45px;
    font-size: 45px;
  }  
  
  @media (max-width: 485px) {
    line-height: 35px;
    font-size: 35px;
  }
`;

export const TextMark2 = styled.div`
  font-weight: 300;
  font-size: 18px;
  margin-top: 20px;

  @media (max-width: 1170px) {
    font-size: 17px;
    margin-top: 10px;
    line-height: 20px;
  }
`;

export const OutLogoDaniel = styled.div`
  width: 82%;
  margin: 0 auto;
  margin-top: 40px;

  @media (max-width: 1480px) {
    width: 50%;
    margin-top: 20px;
  }
`;

export const OutBus = styled.div`
  width: 1400px;
  margin: 0 auto;
  margin-top: 9vw;

  @media (max-width: 1450px) {
    width: 90%;
  }  
  
  @media (max-width: 700px) {
    margin-top: 5vw;
  }
`;

export const OutImgBus = styled.div``;

export const TextBus1 = styled.div`
  font-weight: bold;
  font-size: 40px;
  text-align: center;
  width: 75%;
  margin: 0 auto;
  margin-top: -11vh;
  line-height: 44px;

  @media (max-width: 1450px) {
    margin-top: -5vw;
    line-height: 2.5vw;
    font-size: 2.5vw;
  }

  @media (max-width: 800px) {
    margin-top: -7vw;
    line-height: 3vw;
    font-size: 3vw;
    width: 87%;
  }

  @media (max-width: 550px) {
    margin-top: -8vw;
    line-height: 3.5vw;
    font-size: 3.5vw;
    width: 92%;
  }
`;

// export const TopPartners = styled.div`
//     background-image: url(${ImgBgTopPartners});
//     background-position: center;
//     background-size: cover;
//     height: 58vw;
//     display: flex;
//     flex-direction: column;

//     @media (max-width: 1040px) {
//         display: none;
//     }
// `;
