import styled from 'styled-components';
import BgLowExpose from "../../Images/BgLowExpose.jpg";

export const OutExposeBrand = styled.section`
    width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 7vw;
    flex-wrap: wrap;
`;

export const OutImageExpose = styled.div`
    width: 64%;

    img {
        margin-bottom: -7px;
    }

    @media (max-width: 1180px) {
        width: 55%;
    }   

    @media (max-width: 930px) {
        width: 50%;
    } 

    @media (max-width: 850px) {
        width: 100%;
    }
`;

export const TopExpose = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;

    @media (max-width: 850px) {
        height: auto;
        padding-top: 15px;
        padding-bottom: 15px;
    }
`;

export const RightExpose = styled.div`
    width: 35.8%;
    border: 1px solid #446fe7;
    border-left: 0px;
    border-top-right-radius: 11px;
    border-bottom-right-radius: 11px;

    @media (max-width: 1180px) {
        width: 44%;
    }     
    
    @media (max-width: 930px) {
        width: 49%;
    } 

    @media (max-width: 850px) {
        width: 100%;
        border: 1px solid #446fe7;
        border-top: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 11px;
        border-bottom-left-radius: 11px;
    }
`;

export const TextExpose1 = styled.div`
    font-weight: bold;
    font-size: 45px;
    width: 90%;
    line-height: 52px;

    @media (max-width: 1610px) {
        font-size: 38px;
        line-height: 43px;
    }    
    
    @media (max-width: 1320px) {
        font-size: 25px;
        line-height: 25px;
    }

    @media (max-width: 850px) {
        font-size: 25px;
        line-height: 34px;
        width: 90%;
    }

    @media (max-width: 430px) {
        font-size: 21px;
    }
`;

export const LowExpose = styled.div`
    background-image: url(${BgLowExpose});
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 50%;
    justify-content: center;
    border-bottom-right-radius: 11px;

    @media (max-width: 850px) {
        height: auto;
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom-left-radius: 11px;
    }
`;

export const OutLowExpose = styled.div`
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
`;

export const TextExpose2 = styled.div`
    font-weight: bold;
    font-size: 22px;
    text-transform: uppercase;
`;

export const ItemExpose = styled.a`
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
`;

export const OutIconExpose = styled.div`
    margin-top: 5px;
    width: 23px;

    @media (max-width: 430px) {
        width: 16px;
    }
`;

export const TextExpose3 = styled.div`
    font-weight: 300;
    font-size: 22px;
    padding-left: 12px;
    width: 92%;

    @media (max-width: 1610px) {
        font-size: 18px;
    }   
    
    @media (max-width: 1320px) {
        font-size: 16px;
        padding-left: 5px;
    }

    @media (max-width: 430px) {
        font-size: 14px;
    }
`;

export const OutTakeShow = styled.div`
    width: 85%;
    margin: 0 auto;
`;

export const TopTakeShow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 115px;
`;

export const RiskTakeShow1 = styled.div`
    border: 2px solid #446fe7;
    border-right: 0px;
    border-bottom: 0px;
    width: 22%;
    border-top-left-radius: 30px;
    height: 48px;

    @media (max-width: 700px) {
        width: 12%;
    }
`;

export const RiskTakeShow2 = styled.div`
    border: 2px solid #446fe7;
    border-left: 0px;
    border-bottom: 0px;
    width: 22%;
    border-top-right-radius: 30px;
    height: 48px;

    @media (max-width: 700px) {
        width: 12%;
    }
`;

export const TextTakeShow1 = styled.div`
    font-size: 40px;
    line-height: 40px;
    font-weight: bold;
    color: #446fe7;
    width: 51%;
    margin: 0 auto;
    margin-top: -19px;
    text-align: center;

    @media (max-width: 890px) {
        font-size: 31px;
        line-height: 31px;
    }    

    @media (max-width: 700px) {
        width: 70%;
        font-size: 23px;
        line-height: 27px;
    }
`;

export const ItemTakeShow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 80px;

    @media (max-width: 1320px) {
        margin-top: 60px;
    }

    @media (max-width: 850px) {
        flex-wrap: wrap;
    }
`;

export const LeftItemTakeShow = styled.div`
    width: 36%;

    @media (max-width: 850px) {
        width: 100%;
    }
`;

export const TextTakeShow2 = styled.div`
    font-size: 40px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 45px;

    @media (max-width: 1300px) {
        font-size: 28px;
        line-height: 33px;
    }    
    
    @media (max-width: 950px) {
        font-size: 27px;
        line-height: 27px;
    }
`;

export const TextTakeShow3 = styled.div`
    font-weight: 300;
    font-size: 18px;
    margin-top: 20px;
    height: 26vw;
    overflow: hidden scroll;
    padding-right: 30px;
    color: rgb(255, 255, 255);

    @media (max-width: 1300px) {
        margin-top: 5px;
        padding-right: 10px;
    }

    @media (max-width: 850px) {
        height: 35vw;
    }    
    
    @media (max-width: 500px) {
        height: 53vw;
    }
`;    