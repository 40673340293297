import styled from "styled-components";
// import ImgBgTopPartners from "../../Images/BgTopPartners.jpg";

export const OutInternet = styled.section`
  width: 1600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 90px;
  flex-wrap: wrap;

  @media (max-width: 1650px) {
    width: 90%;
    padding-top: 5vw;
  }
`;

export const LeftInternet = styled.div`
  width: 40%;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const TextInternet1 = styled.div`
  font-weight: bold;
  font-size: 75px;
  text-transform: uppercase;

  @media (max-width: 1050px) {
    font-size: 55px;
  }
`;

export const TextInternet2 = styled.div`
  font-weight: 300;
  font-size: 18px;
`;

export const ContentRedes = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ItemRedes = styled.div`
  font-weight: 900;
  font-size: 18px;
  width: 45%;
  display: flex;
  align-items: center;
  border: 2px solid #446fe7;
  border-radius: 28px;
  height: 55px;
  justify-content: center;
  margin-top: 45px;
  cursor: pointer;

  @media (max-width: 1050px) {
    width: 48%;
    height: 45px;
    margin-top: 15px;
  }
`;

export const OutImgRedes = styled.div`
  margin-bottom: -4px;
  margin-right: 15px;

  @media (max-width: 1050px) {
    margin-right: 7px;
    width: 25px;
  }
`;

export const TextRedes1 = styled.div`
    font-weight: 900;
    font-size: 18px;

    @media (max-width: 1050px) {
        font-size: 15px;
    }
`;

export const RightInternet = styled.div`
  width: 59%;

  @media (max-width: 800px) {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
  }
`;

export const OutPartner = styled.section`
  width: 1600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 4vw;
  flex-wrap: wrap;

  @media (max-width: 1650px) {
    width: 90%;
  }
`;

export const LeftPartner = styled.div`
  width: 54%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const ContenetLeftPartner = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  flex-wrap: wrap;

  @media (max-width: 900px) {
    margin-top: 15px;
  }

  @media (max-width: 500px) {
    margin-top: 10px;
  }
`;

export const ItemLeftPartner = styled.div`
  font-weight: 900;
  font-size: 18px;
  color: #446fe7;
  width: 21%;
  border: 2px solid #446fe7;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  cursor: pointer;

  @media (max-width: 900px) {
    width: 23%;
  }  
  
  @media (max-width: 500px) {
    width: 48%;
    margin-top: 10px;
  }
`;

export const RightPartner = styled.div`
  width: 43%;

  @media (max-width: 900px) {
    width: 100%;
    margin-top: 4vw;
  }
`;

export const LogoMercedes = styled.div`
  width: 270px;
`;

export const TextPartner1 = styled.div`
  font-weight: bold;
  font-size: 45px;
  text-transform: uppercase;
  line-height: 50px;
  margin-top: 10px;
  margin-bottom: 5px;

  @media (max-width: 1650px) {
    font-size: 35px;
    line-height: 36px;
  }

  @media (max-width: 1254px) {
    font-size: 26px;
    line-height: 28px;
  }  
  
  @media (max-width: 500px) {
    font-size: 21px;
    line-height: 28px;
  }
`;

export const TextPartner2 = styled.div`
  font-weight: 300;
  font-size: 18px;
  padding-top: 25px;

  @media (max-width: 1650px) {
    padding-top: 15px;
  }
`;
