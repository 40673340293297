import styled from "styled-components";
import ImgBgTopShows from "../../Images/BgTopShows.jpg";

export const OutShows = styled.section``;

export const TopShows = styled.div`
  background-image: url(${ImgBgTopShows});
  background-position: center;
  background-size: cover;
  height: 58vw;
  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    height: 84vw;
  }
`;

export const TextShows1 = styled.div`
  font-weight: 300;
  font-size: 65px;
  text-transform: uppercase;
  text-align: center;
  padding-top: 17vw;

  @media (max-width: 1400px) {
    font-size: 50px;
  }

  @media (max-width: 1000px) {
    font-size: 35px;
  }

  @media (max-width: 700px) {
    font-size: 26px;
    padding-top: 25vw;
  }

  @media (max-width: 550px) {
    width: 90%;
    margin: 0 auto;
    line-height: 30px;
  }
`;

export const TextShows2 = styled.div`
  font-weight: bold;
  font-size: 91px;
  text-transform: uppercase;
  text-align: center;

  @media (max-width: 1400px) {
    font-size: 75px;
    line-height: 75px;
  }

  @media (max-width: 1000px) {
    font-size: 50px;
    line-height: 50px;
  }

  @media (max-width: 700px) {
    font-size: 35px;
    line-height: 37px;
  }

  @media (max-width: 550px) {
    font-size: 30px;
    line-height: 45px;
  }
`;

export const ContentShows = styled.div`
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  margin-top: -16vw;

  @media (max-width: 800px) {
    margin-top: -38vw;
  }

  @media (max-width: 700px) {
    margin-top: -24vw;
  }
`;

export const OutImgDaniel = styled.div`
  width: 338px;
  margin-right: -35px;
  z-index: 2;
  position: relative;
  margin-top: -40px;

  @media (max-width: 1500px) {
    width: 279px;
    margin-right: -26px;
  }

  @media (max-width: 1000px) {
    width: 190px;
    margin-right: -15px;
  }

  @media (max-width: 850px) {
    display: none;
  }
`;

export const RightContentShows = styled.div`
  width: 82%;

  @media (max-width: 850px) {
    width: 100%;
  }
`;

export const ImgContentShows = styled.div``;

export const OutRightContent = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-top: 60px;

  @media (max-width: 1000px) {
    width: 87%;
    padding-top: 40px;
  }

  @media (max-width: 850px) {
    width: 90%;
    padding-top: 0px;
  }
`;

export const TextShows3 = styled.div`
  font-size: 75px;
  font-weight: bold;
  text-transform: uppercase;
  width: 603px;
  line-height: 77px;
  margin-top: -28vw;

  @media (max-width: 1500px) {
    font-size: 60px;
    line-height: 60px;
  }

  @media (max-width: 1230px) {
    font-size: 50px;
    line-height: 50px;
    width: auto;
  }

  @media (max-width: 850px) {
    margin-top: -31.5vw;
    font-size: 37px;
    line-height: 37px;
  }

  @media (max-width: 550px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const TextShows4 = styled.div`
  font-size: 18px;
  font-weight: 300;
  margin-top: 30px;
  margin-bottom: 20px;

  @media (max-width: 1500px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  @media (max-width: 550px) {
    font-size: 16px;
  }
`;

export const OutItemsShows = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;

  @media (max-width: 850px) {
    width: 100%;
  }
`;

export const ItemShows = styled.div`
  display: flex !important;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const LeftItemShows = styled.div`
  width: 30%;

  @media (max-width: 1620px) {
    height: 33vw;
    overflow: hidden scroll;
    padding-right: 30px;
    color: rgb(255, 255, 255);
  }

  @media (max-width: 900px) {
    width: 95%;
    overflow: hidden scroll;
    padding-right: 25px;
  }

  @media (max-width: 600px) {
    width: 90%;
    padding-right: 10px;
    height: 300px;
  }
`;

export const TextItemShows1 = styled.div`
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 20px;
  color: #446fe7;
`;

export const TextItemShows2 = styled.div``;

export const RightItemFotos = styled.div`
  height: 33vw;
  width: 63%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (max-width: 900px) {
    width: 100%;
    margin-top: 30px;
    height: 40vw;
  }

  @media (max-width: 600px) {
    height: 45vw;
  }

  @media (max-width: 450px) {
    height: 55vw;
  }
`;

export const TextItemShows = styled.div`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  font-weight: bold;
  border: 2px solid #223a7d;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 44px;
  width: 31%;
  margin-top: 30px;
  cursor: pointer;

  @media (max-width: 1630px) {
    font-size: 13px;
    line-height: 15px;
    margin-top: 15px;
    height: 40px;
    width: 32%;
  }

  @media (max-width: 1360px) {
    font-size: 12px;
    line-height: 14px;
  }

  @media (max-width: 1230px) {
    width: 48%;
  }

  @media (max-width: 650px) {
    margin-top: 10px;
    font-size: 13px;
    height: 35px;
  }

  @media (max-width: 550px) {
    width: 100%;
  }
`;

export const RiskMoreShows = styled.div`
  height: 2px;
  background-color: #223a7d;
  width: 100%;
  margin-top: 60px;

  @media (max-width: 1500px) {
    margin-top: 30px;
  }
`;

export const OutMoreShows = styled.div`
  display: flex;
  border: 2px solid #223a7d;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-bottom-right-radius: 24px;
  border-bottom-left-radius: 24px;
  margin: 0 auto;
  border-top: 0px;
  cursor: pointer;

  @media (max-width: 1500px) {
    width: 190px;
  }
`;

export const TextMoreShows = styled.div`
  font-weight: 500;
  text-transform: uppercase;
  font-size: 18px;
  color: #5990ed;
`;

export const OutImgMoreShows = styled.div`
  margin-left: 15px;
  margin-top: 7px;
`;

export const BgShows2 = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 200;
  background-color: #000522;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const XShows = styled.div`
  font-size: 25px;
  font-weight: bold;
  position: absolute;
  color: #fff;
  top: 15px;
  right: 35px;
  cursor: pointer;
`;

export const OutShows2 = styled.div`
  width: 680px;
  margin: 0 auto;
`;

export const OutImgShows2 = styled.div``;

export const ButtonLookPictures = styled.div`
  text-transform: uppercase;
  font-size: 19px;
  font-weight: bold;
  background-color: rgb(68, 111, 231);
  width: 200px;
  margin: 0 auto;
  margin-top: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  cursor: pointer;
`;

export const OutShows3 = styled.div`
  width: 680px;
  margin: 0 auto;
`;
