/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";

import ImgExpose from "../../Images/ImgExpose.jpg";
import IconExpose1 from "../../Images/IconExpose1.png";
import IconExpose2 from "../../Images/IconExpose2.png";

function TakeShow(props: any) {
  const history = useHistory();
  const [menu, setMenu] = useState(0);

  const MandaProducao = () => {
    history.push("/Producao");
    setMenu(0);
  };

  const MandaEmail = () => {
    window.open("https://danielcorp.com.br/", "_blank");
    setMenu(0);
  };

  const MandaWhats = () => {
    window.open("https://danielcorp.com.br/", "_blank");
    setMenu(0);
  };

  useEffect(() => {
    const hash = props.history.location.hash;
    if (hash && document.getElementById(hash.substr(1))) {
      // Check if there is a hash and if an element with that id exists
      document.getElementById(hash.substr(1))?.scrollIntoView({ behavior: "smooth" });
    }
    // }, []);
  }, [props.history.location.hash]);

  return (
    <>
      <S.OutExposeBrand>
        <S.OutImageExpose>
          <img src={ImgExpose} />
        </S.OutImageExpose>
        <S.RightExpose>
          <S.TopExpose>
            <S.TextExpose1>COMO FAZER PARCERIAS COM A MARCA DANIEL?</S.TextExpose1>
          </S.TopExpose>
          <S.LowExpose>
            <S.OutLowExpose>
              <S.TextExpose2>CONTATO PARA PARCERIAS</S.TextExpose2>
              <S.ItemExpose target="_blank" href="mailto:alessandra@danielpromocoes.com.br">
                <S.OutIconExpose>
                  <img src={IconExpose1} />
                </S.OutIconExpose>
                <S.TextExpose3>alessandra@danielpromocoes.com.br</S.TextExpose3>
              </S.ItemExpose>
              <S.ItemExpose target="_blank" href="https://wa.me/5511974124751">
                <S.OutIconExpose>
                  <img src={IconExpose2} />
                </S.OutIconExpose>
                <S.TextExpose3>11 97412.4751</S.TextExpose3>
              </S.ItemExpose>
            </S.OutLowExpose>
          </S.LowExpose>
        </S.RightExpose>
      </S.OutExposeBrand>

      <S.OutTakeShow>
        <S.TopTakeShow>
          <S.RiskTakeShow1 />
          <S.TextTakeShow1>LEVE ESSE SHOW PARA SUA EMPRESA!</S.TextTakeShow1>
          <S.RiskTakeShow2 />
        </S.TopTakeShow>

        <S.ItemTakeShow>
          <S.LeftItemTakeShow>
            <S.TextTakeShow2>DANIEL 40 ANOS CELEBRA JOÃO PAULO E DANIEL</S.TextTakeShow2>
            <S.TextTakeShow3>
              “Daniel 40 Anos Celebra João Paulo & Daniel” é um show dedicado ao repertório da dupla que emocionou o
              país na década de 90. Mais do que uma homenagem, os shows serão um marco na vida de Daniel ao cantar os
              maiores sucessos da época.
              <br />
              <br />
              “Eu posso dizer que esse é o projeto da minha vida. Se não fosse tudo o que vivemos juntos, hoje eu não
              estaria diante do público. Momentos incríveis que vivi e que só a música tem o poder de deixar registrado.
              Tudo se deve a essa parceria, ao meu saudoso irmão e parceiro que nos deixou há 25 anos. É realmente muito
              prazeroso o poder que a música tem e saber, principalmente, que fizemos algo que ficou registrado e que
              novas gerações estão presenciando”, explica Daniel.
              <br />
              <br />
              Daniel agora evidencia a sua versatilidade, jovialidade e talento tão explorado ao longo da sua incrível trajetória promovendo o DVD do espetáculo “Daniel 40 Anos Celebra João Paulo & Daniel”.
              <br />
              <br />
              A histórica e emocionante apresentação aconteceu, no dia 21 de dezembro de 2022, na Vibra São Paulo, uma das casas de shows e espetáculos mais importantes da América Latina.
              <br />
              <br />
              João Paulo e Daniel formaram uma das duplas de maior sucesso da música brasileira. Os discos lançados entre 1995 e 1997 venderam mais de 5 milhões de cópias. Entre os maiores sucessos, está a canção “Estou Apaixonado”, versão em português de “Estoy Enamorado”, de Donato e Estefano, que foi tema da novela Explode Coração (TV Globo) em 1995.
              <br />
              <br />
              Mais do que uma homenagem, o espetáculo “Daniel 40 Anos Celebra João Paulo & Daniel” promete ser emocionante, único, elegante e extremamente sofisticado. É o mais novo capítulo a entrar para a história da carreira de um dos grandes pilares da música brasileira.       
            </S.TextTakeShow3>
          </S.LeftItemTakeShow>
          <iframe className="IframeShow" src="https://www.youtube.com/embed/ldNtfHakuQg?si=QX82YtNx3TrzM6wY" />
        </S.ItemTakeShow>

        {/* 
        <S.ItemTakeShow>
          <iframe
            className="IframeShow IframeShow2Desk"
            src="https://player.vimeo.com/video/390757267?dnt=1&app_id=122963"
          />
          <S.LeftItemTakeShow>
            <S.TextTakeShow2>DANIEL INTERPRETA ROBERTO CARLOS</S.TextTakeShow2>
            <S.TextTakeShow3>
              Uma nova opção de show no currículo do cantor Daniel surge de sua admiração pelo rei Roberto Carlos e suas
              canções.
              <br />
              <br />
              O espetáculo “Daniel interpreta Roberto Carlos” será apresentado em algumas cidades do Brasil trazendo
              músicas escolhidas a dedo dentro do vasto repertório de sucessos do astro.
              <br />
              <br />
              “Eu sempre cantei músicas do Roberto, já gravei algumas e todos sabem da minha admiração por ele, que é
              uma grande referência musical para mim. Ano passado fizemos uma experiência muito boa de interpretar
              diversas canções dele para o programa Versões Bis do Multishow e isso trouxe à tona o meu desejo de
              realizar este projeto”, conta Daniel.
              <br />
              <br />
              Ele também comenta a dificuldade de selecionar este repertório: “são muitas as músicas e escolher as que
              entram no show foi difícil, optei por aquelas que me tocam mais fundo; ao mesmo tempo em que é uma
              homenagem a ele é uma grande realização para mim, só pelo fato de ele ter autorizado esse trabalho sou
              muito grato. Espero corresponder a esta confiança e agradar os meus fãs e fãs do Roberto”.
              <br />
              <br />
              Próximos shows:
              <br />
              29 de março – Santa Maria/RS
              <br />
              12 de junho – Rio de Janeiro/Vivo Rio
              <br />
            </S.TextTakeShow3>
          </S.LeftItemTakeShow>
          <iframe
            className="IframeShow IframeShow2Resp"
            src="https://player.vimeo.com/video/390757267?dnt=1&app_id=122963"
          />
        </S.ItemTakeShow>

        <S.ItemTakeShow>
          <S.LeftItemTakeShow>
            <S.TextTakeShow2>MEU REINO ENCANTADO</S.TextTakeShow2>
            <S.TextTakeShow3>
              Daniel em repertório raiz. Cercado por uma roda de músicos, o cantor Daniel retoma suas influências
              sertanejas para apresentar um show acústico intitulado Meu Reino Encantado, com repertório que marcou seu
              início de carreira.
              <br />
              <br />
              Após o sucesso das apresentações no Sesc Vila Mariana em São Paulo, quando formatou este show para o
              projeto “NO BRAÇO DESSA VIOLA”, Daniel decidiu oferecer essa segunda opção de espetáculo para apreciadores
              deste estilo musical.
              <br />
              <br />
              Atendendo a pedidos de interessados pelo show, o Escritório Daniel Promoções colocou-o à disposição em sua
              agenda, mas mantém o seu carro chefe: a turnê Amores Seletivos.
              <br />
              <br />
              Além de canções que aprendeu quando ainda era criança, Daniel apresenta neste show sucessos que gravou
              sozinho como “Filho do Mato”, ao lado do companheiro João Paulo como “Dia de Visita” e “A Loira do Carro
              Branco”, músicas do cancioneiro tradicional e até canções produzidas para o filme O Menino da Porteira
              (2009).
              <br />
              <br />
              Nascido em Brotas, interior de São Paulo, a paixão de Daniel pela música começou muito cedo e sempre foi
              incentivada pela família. Participou de diversos festivais regionais de música sertaneja, inclusive
              concorrendo com o amigo João Paulo, com quem formou dupla em 1980. A morte de João Paulo em 1997 rompeu a
              trajetória dos amigos e Daniel decidiu seguir cantando sozinho.
              <br />
              <br />
              Vencedor de dois Grammys Latinos (2009 com o álbum “As músicas do filme O Menino da Porteira” e em 2017
              com seu albúm de carreira intitulado DANIEL), o cantor Daniel tem mais de 30 anos de carreira e uma paixão
              pelo universo sertanejo. No início dos anos 2000 ele lançou uma trilogia chamada Meu Reino Encantado, três
              álbuns com grandes sucessos da música sertaneja raiz e muitas participações especiais, que servem de
              inspiração para este formato de show que ele apresenta agora.
            </S.TextTakeShow3>
          </S.LeftItemTakeShow>
          <iframe className="IframeShow" src="https://player.vimeo.com/video/360563736?dnt=1&app_id=122963" />
        </S.ItemTakeShow>
        */}
      </S.OutTakeShow>
    </>
  );
}

export default withRouter(TakeShow);
