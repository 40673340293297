/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";

import ImgCharismatic from "../../Images/ImgCharismatic.jpg";
import ImgMark from "../../Images/ImgMark.png";
import ImgMarkResp from "../../Images/ImgMarkResp.png";
import ImgLogoDaniel from "../../Images/LogoDaniel.png";
import ImgBgBus from "../../Images/BgBus.png";

function Charismatic(props: any) {
  const history = useHistory();
  const [menu, setMenu] = useState(0);

  const MandaProducao = () => {
    history.push("/Producao");
    setMenu(0);
  };

  const MandaCorporativo = () => {
    window.open("https://danielcorp.com.br/", "_blank");
    setMenu(0);
  };

  useEffect(() => {
    const hash = props.history.location.hash;
    if (hash && document.getElementById(hash.substr(1))) {
      // Check if there is a hash and if an element with that id exists
      document.getElementById(hash.substr(1))?.scrollIntoView({ behavior: "smooth" });
    }
    // }, []);
  }, [props.history.location.hash]);

  return (
    <>
      <S.OutImgCharismatic>
        <img src={ImgCharismatic} />
      </S.OutImgCharismatic>
      {/* 
      <S.OutMark>
        <div style={{position: "absolute", marginTop: "-90px"}} id='Licenciamento'></div>
        <S.OutImgMark>
          <img src={ImgMark} />
        </S.OutImgMark>        
        <S.OutImgMarkResp>
          <img src={ImgMarkResp} />
        </S.OutImgMarkResp>
        <S.OutContentMark>
          <S.TextMark1>MARCA LICENCIAMENTO</S.TextMark1>
          <S.TextMark2>
            Pesquisas recentes do Instituto Ilumeo demonstraram que o nome do cantor Daniel remete as pessoas ao bem e
            aquilo que é correto. Sua índole e reputação trazem para a marca Daniel uma bagagem que não se constrói da
            noite para o dia. A imagem do artista pode agregar valor a produtos e passar a credibilidade necessária ao
            mercado.
          </S.TextMark2>
          <S.OutLogoDaniel>
            <img src={ImgLogoDaniel} />
          </S.OutLogoDaniel>
        </S.OutContentMark>
      </S.OutMark>
     

      <S.OutBus>
        <S.OutImgBus>
          <img src={ImgBgBus} />
        </S.OutImgBus>
        <S.TextBus1>
          Nos últimos 5 anos são aproximadamente 100 shows anuais, portanto quase 100 municípios, mais de 400.000 km rodados.
        </S.TextBus1>   
      </S.OutBus>
       */}
    </>
  );
}

export default withRouter(Charismatic);
