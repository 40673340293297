/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";

import Load from "../Load";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ReactHtmlParser from "react-html-parser";

import ImgCommercial from "../../Images/ImgCommercial.png";
import ImgBgCommercial from "../../Images/BgCommercial.jpg";

import useData from "../../Utils/useData";

function Partners(props: any) {
  const history = useHistory();
  const [data, setData] = useState<any>([]);
  const [data2, setData2] = useState<any>([]);
  const [data3, setData3] = useState<any>([]);
  const [menu, setMenu] = useState(0);
  const [comercial, setComercial] = useState(0);
  const [titulo, setTitulo] = useState("");
  const [texto, setTexto] = useState("");
  const [tipoVideo, setTipoVideo] = useState(0);
  const [tipoItem, setTipoItem] = useState(0);
  const [link, setLink] = useState("");
  const [load, setLoad] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const MandaCurriculo = () => {
    window.open(
      "https://danielcorp.com.br/wp-content/uploads/2015/12/Daniel_curriculo.pdf",
      "_blank"
    );
  };

  const MandaMateria = () => {
    window.open(
      "https://danielcorp.com.br/wp-content/uploads/2015/12/Materia-Valor-Economico-02-de-Abril-Brasil-Cacau.jpg",
      "_blank"
    );
  };

  const loadData = () => {
    setLoad(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useData()
      .getData("parceiros.php")
      .then((response: any) => {
        setData(response);
        setLoad(false);
      })
      .catch((err) => {
        console.info(err);
        setLoad(false);
      });

    setLoad(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useData()
      .getData("comerciais.php")
      .then((response: any) => {
        setData2(response);
        setLoad(false);
      })
      .catch((err) => {
        console.info(err);
        setLoad(false);
      });
  };

  const AbreComercial = (idComercial: any) => {
    setComercial(1);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    console.log(tipoItem);

    const raw = JSON.stringify({
      idTbComercial: idComercial,
    });

    fetch("https://danielcorp.com.br/app_site/FotosComercial.php", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => response.text())
      .then((response: any) => {
        setData3(JSON.parse(response));
        // console.log(JSON.parse(response)[0]);
        // console.log(JSON.parse(response)[0].id_tb_show);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const hash = props.history.location.hash;
    if (hash && document.getElementById(hash.substr(1))) {
      // Check if there is a hash and if an element with that id exists
      document
        .getElementById(hash.substr(1))
        ?.scrollIntoView({ behavior: "smooth" });
    }
    // }, []);
  }, [props.history.location.hash]);

  return (
    <>
      {load == true && <Load status={true} />}

      <S.OutPartners>
        <S.TopPartners>
          <S.TextPartners2>MAIS DE 300 MILHÕES</S.TextPartners2>
          <S.TextPartners1>DE VIEWS NO YOUTUBE</S.TextPartners1>
        </S.TopPartners>

        <div id="Parceiros"></div>

        <S.ContentPartners>
          <S.TextPartners3>MARCAS PARCEIRAS JÁ ATENDIDAS</S.TextPartners3>
          <S.OutItemsPartner>
            {data &&
              data.map((data: any, index: React.Key | null | undefined) => (
                <S.ItemPartner key={index}>
                  <img
                    src={`https://danielcorp.com.br/upload/parceiro/${data.id_tb_parceiro}a.${data.c_extensao1}`}
                  />
                </S.ItemPartner>
              ))}
          </S.OutItemsPartner>
        </S.ContentPartners>
      </S.OutPartners>
      {/* 
      <S.OutArtist>
        <S.ImageArtist>
          <img src={ImgBgArtist} />
        </S.ImageArtist>
        <S.ImageArtistResp>
          <img src={BgArtistResp} />
        </S.ImageArtistResp>
        <S.ContentArtist>
          <S.TextArtist1>ARTISTA VERSÁTIL E COMPLETO</S.TextArtist1>
          <S.OutButtonArtist>
            <S.ButtonArtist onClick={MandaCurriculo}>Currículo de Daniel</S.ButtonArtist>
            <S.ButtonArtist onClick={MandaMateria}>MATÉRIA VALOR ECONÔMICO</S.ButtonArtist>
          </S.OutButtonArtist>
        </S.ContentArtist>
        <S.ImageArtist2>
          <img src={ImgArtist} />
        </S.ImageArtist2>
        <div id="Comerciais"></div>
      </S.OutArtist>
      */}

      <S.OutCommercial id="Comerciais">
        <S.OutContentCommercial>
          <S.OutImgBgCommercial>
            <img src={ImgBgCommercial} />
          </S.OutImgBgCommercial>
          <S.ContentCommercial>
            <S.TextCommercial1>COMERCIAIS de tv</S.TextCommercial1>
            <S.TextCommercial2>
              O cantor Daniel já realizou diversos comerciais de televisão com
              desenvoltura e clareza na transmissão das informações. Marcas dos
              mais variados segmentos já tiveram sua participação em vídeos
              veiculados nacional e regionalmente.
            </S.TextCommercial2>
            <S.OutItemsCommercial>
              {data2 &&
                data2.map(
                  (data2: any, index2: React.Key | null | undefined) => (
                    <S.TextItemCommercial
                      key={index2}
                      onClick={() => {
                        AbreComercial(data2.id_tb_comercial);
                        setTitulo(data2.c_titulo);
                        setTipoItem(data2.c_tipo_item);
                        setTipoVideo(data2.c_tipo_video);
                        setLink(data2.c_link);
                        setTexto(data2.c_texto);
                      }}
                    >
                      {data2.c_titulo}
                    </S.TextItemCommercial>
                  )
                )}
            </S.OutItemsCommercial>
          </S.ContentCommercial>
        </S.OutContentCommercial>
        <S.OutImgCommercial>
          <img src={ImgCommercial} />
        </S.OutImgCommercial>
      </S.OutCommercial>

      {comercial === 1 && (
        <S.BgCommercial2>
          <S.XCommercial onClick={() => setComercial(0)}>X</S.XCommercial>
          <S.ContentCommercial2>
            <S.LeftCommercial>
              <S.TextLeftCommercial1>EMPRESA</S.TextLeftCommercial1>
              <S.TextLeftCommercial2>{titulo}</S.TextLeftCommercial2>
              <S.TextLeftCommercial3>{texto}</S.TextLeftCommercial3>
            </S.LeftCommercial>
            {tipoItem == 1 && (
              <>
                {tipoVideo == 0 ? (
                  <>
                    <iframe
                      src={`https://www.youtube.com/embed/${link}`}
                      width="100%"
                      className="IframeCommercial"
                      frameBorder="0"
                      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                      loading="lazy"
                    />
                  </>
                ) : (
                  <iframe
                    src={`https://player.vimeo.com/video/${link}`}
                    width="100%"
                    className="IframeCommercial"
                    frameBorder="0"
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                    loading="lazy"
                  />
                )}
              </>
            )}

            {tipoItem == 0 && (
              <S.OutItemsShows>
                <Slider className="SliderBanner1" {...settings}>
                  {data3 &&
                    data3.map(
                      (data3: any, index3: React.Key | null | undefined) => (
                        <S.ItemShows key={index3}>
                          {/* 
                        <S.LeftItemShows>
                          <S.TextItemShows1>{titulo}</S.TextItemShows1>
                          <S.TextItemShows2>{ReactHtmlParser(texto)}</S.TextItemShows2>
                        </S.LeftItemShows>
                        */}
                          <S.RightItemFotos
                            style={{
                              backgroundImage:
                                "url('https://danielcorp.com.br/upload/comercial/" +
                                data3.id_tb_foto_comercial +
                                "." +
                                data3.c_extensao +
                                "')",
                            }}
                          />
                        </S.ItemShows>
                      )
                    )}
                </Slider>
              </S.OutItemsShows>
            )}
          </S.ContentCommercial2>
        </S.BgCommercial2>
      )}
    </>
  );
}

export default withRouter(Partners);
