/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";

import { Alert } from "../atoms";
import ReCAPTCHA from "react-google-recaptcha";

import LogoOpus from "../../Images/LogoOpus.png";

import IconExpose1 from "../../Images/IconExpose1.png";
import IconExpose2 from "../../Images/IconExpose2.png";
import IconRedes1 from "../../Images/IconRedes1.png";
import IconRedes2 from "../../Images/IconRedes2.png";
import IconRedes3 from "../../Images/IconRedes3.png";
import IconRedes4 from "../../Images/IconRedes4.png";
import RiskContact from "../../Images/RiskContact.png";
import Load from "../Load";

function Contact(props: any) {
  const history = useHistory();
  const [load, setLoad] = useState(false);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [celular, setCelular] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [captcha, setCaptcha] = useState("");

  function onChange(value: React.SetStateAction<string>) {
    setCaptcha("xxx");
  }

  const MandaFace = () => {
    window.open("https://www.facebook.com/cantorDaniel/", "_blank");
  };

  const MandaYoutube = () => {
    window.open("https://www.youtube.com/danieloficial", "_blank");
  };

  const MandaInsta = () => {
    window.open("https://www.instagram.com/cantordaniel/", "_blank");
  };

  const MandaTwitter = () => {
    window.open("https://twitter.com/cantordaniel", "_blank");
  };

  const MandaCorporativo = () => {
    window.open("https://danielcorp.com.br/", "_blank");
  };

  const phoneMask = (value: string) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d{4})/, "$1-$2");
  };

  const clearFields = () => {
    setNome("");
    setCelular("");
    setEmail("");
    setMensagem("");
  };

  const onSubmit = () => {
    let validaMenssages = [];
    validaMenssages.push(
      <div style={{ marginBottom: "10px" }}>
        Atenção, segue abaixo campos obrigatórios:
      </div>
    );
    let valida = false;
    if (nome === "" || nome === undefined) {
      validaMenssages.push(<div key={"Nome"}>- Nome</div>);
      valida = true;
    }
    if (celular === "" || celular === undefined) {
      validaMenssages.push(<div key={"Telefone"}>- Telefone</div>);
      valida = true;
    }
    // alert(captcha);
    // if (captcha === '' || captcha === undefined) {
    //   validaMenssages.push(<div key={'Telefone'}>- Verificação de robô</div>);
    //   valida = true;
    // }
    if (email === "" || email === undefined) {
      validaMenssages.push(<div key={"E-mail"}>- E-mail</div>);
      valida = true;
    } else {
      if (
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
        )
      ) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        null;
      } else {
        validaMenssages.push(<div key={"E-mail"}>- E-mail (inválido)</div>);
        valida = true;
      }
    }
    if (mensagem === "" || mensagem === undefined) {
      validaMenssages.push(<div key={"Mensagem"}>- Mensagem</div>);
      valida = true;
    }
    if (valida) {
      Alert(<div>{validaMenssages}</div>);
    } else {
      setLoad(true);

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        Nome: nome,
        Celular: celular,
        Email: email,
        Mensagem: mensagem,
      });

      validaMenssages = [];

      fetch("https://danielcorp.com.br/app_site/envia_contato.php", {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      })
        .then((response) => response.text())
        .then((response: any) => {
          // console.log(response);
          setLoad(false);
          Alert(
            <>
              <div>Formulário de contato enviado com sucesso!</div>
              <div>Em breve entraremos em contato.</div>
            </>
          );
          clearFields();
          validaMenssages = [];
          // setNome('');
          // setEmail('');
          // setCelular('');
          // setMensagem('');
          setLoad(false);
        })
        .catch((error) => console.log("error", error));
    }
  };

  return (
    <>
      <div
        style={{ position: "absolute", marginTop: "-115px" }}
        id="Contato"
      ></div>
      <S.OutContact>
        <S.LeftContact>
          <S.TextContact1>Contato</S.TextContact1>
          <S.TextContatct2>
            Entre em contato com nossa equipe e tenha um grande nome aliado à
            sua marca.
          </S.TextContatct2>
          {load === true && <Load status={true} />}
          <form className="FormContato">
            <input
              className="InputForm"
              type="text"
              onChange={(e) => setNome(e.target.value)}
              value={nome}
              name="c_nome"
              id="c_nome"
              placeholder="Nome"
            />
            <input
              className="InputForm"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              name="c_email"
              id="c_email"
              placeholder="E-mail"
            />
            <input
              className="InputForm"
              onChange={(e) => setCelular(e.target.value)}
              value={phoneMask(celular)}
              maxLength={15}
              name="c_telefone"
              id="c_telefone"
              placeholder="Telefone"
            />
            <textarea
              className="InputTextArea"
              onChange={(e) => setMensagem(e.target.value)}
              value={mensagem}
              name="c_comentario"
              id="c_comentario"
              placeholder="Comentário"
            />
            <S.BaixoForm>
              <ReCAPTCHA
                sitekey="6LcUqR4oAAAAAAlJEI4LzSvjo9o9JgvfOC1Bv0zn"
                onChange={() => onChange("xx")}
              />
              <button
                onClick={() => onSubmit()}
                type="button"
                className="BotaoEnvia"
              >
                Enviar
              </button>
            </S.BaixoForm>
          </form>
        </S.LeftContact>
        <S.OutRiskContact>
          <img src={RiskContact} />
        </S.OutRiskContact>
        <S.RightContact>
          {/*
          <S.OutItemContact>
            <S.ItemContact>
              <S.OutIconContact>
                <img src={IconExpose1} />
              </S.OutIconContact>
              <S.TextItemContact1>leticia.berbel@opusentretenimento.com</S.TextItemContact1>
            </S.ItemContact>            
            <S.ItemContact>
              <S.OutIconContact>
                <img src={IconExpose2} />
              </S.OutIconContact>
              <S.TextItemContact1>11 91069 1716</S.TextItemContact1>
            </S.ItemContact>
          </S.OutItemContact>
          
          <S.OutItemContact>
            <S.ItemContact>
              <S.OutIconContact>
                <img src={IconExpose1} />
              </S.OutIconContact>
              <S.TextItemContact1>contato@danielpromocoes.com.br</S.TextItemContact1>
            </S.ItemContact>
            <S.ItemContact>
              <S.OutIconContact>
                <img src={IconExpose2} />
              </S.OutIconContact>
              <S.TextItemContact1>14 3653 9898</S.TextItemContact1>
            </S.ItemContact>
          </S.OutItemContact>
 */}
          <S.OutLowBanner>
            <S.OutImageOpus>
              <img src={LogoOpus} />
            </S.OutImageOpus>

            <S.LeftLowBanner>
              <S.TextBanner2>Vendas de Shows</S.TextBanner2>
              <S.TextBanner2>MARCOS MIOTO</S.TextBanner2>
              <S.TextBanner3>daniel@bookingmusic.com.br</S.TextBanner3>
              {/* <S.TextBanner3>+55 (17) 99745-4001</S.TextBanner3> */}
            </S.LeftLowBanner>

            <S.RightLowBanner>
              <S.TextBanner2>Campanhas Publicitárias</S.TextBanner2>
              <S.TextBanner3>ALESSANDRA MESCHINI</S.TextBanner3>
              <S.TextBanner3>
                alessandra.meschini@danielpromocoes.com.br
              </S.TextBanner3>
              <S.TextBanner3>+55 (11) 97412.4751</S.TextBanner3>
            </S.RightLowBanner>
          </S.OutLowBanner>

          <S.OutRedesContact>
            <S.ItemRedesContact onClick={MandaInsta}>
              <S.OutIconRedesContact>
                <img src={IconRedes1} />
              </S.OutIconRedesContact>
              <S.TextRedesContact>Instagram</S.TextRedesContact>
            </S.ItemRedesContact>
            {/* <S.ItemRedesContact onClick={MandaTwitter}>
              <S.OutIconRedesContact>
                <img src={IconRedes3} />
              </S.OutIconRedesContact>
              <S.TextRedesContact>X</S.TextRedesContact>
            </S.ItemRedesContact> */}
            <S.ItemRedesContact onClick={MandaYoutube}>
              <S.OutIconRedesContact>
                <img src={IconRedes2} />
              </S.OutIconRedesContact>
              <S.TextRedesContact>Youtube</S.TextRedesContact>
            </S.ItemRedesContact>
            <S.ItemRedesContact onClick={MandaFace}>
              <S.OutIconRedesContact>
                <img src={IconRedes4} />
              </S.OutIconRedesContact>
              <S.TextRedesContact>Facebook</S.TextRedesContact>
            </S.ItemRedesContact>
          </S.OutRedesContact>
        </S.RightContact>
      </S.OutContact>
    </>
  );
}

export default withRouter(Contact);
