/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";

import ImgCareer from "../../Images/ImgCareer.jpg";
import ImgCommercia2 from "../../Images/ImgCommercia2.png";
import BgContentShows from "../../Images/BgContentShows.png";
import BgEvents from "../../Images/BgEvents.jpg";
import ImgCinema from "../../Images/ImgCinema.jpg";
import ImgEventos from "../../Images/BgEventsResp.jpg";

import useData from "../../Utils/useData";

function Career(props: any) {
  const history = useHistory();
  const [cinema, setCinema] = useState(0);
  const [tituloVideo, setTituloVideo] = useState("TRILHA SONORA EUA");
  const [titulo, setTitulo] = useState(0);
  const [descricao, setDescricao] = useState(0);
  const [id, setId] = useState(0);
  const [extensao, setExtensao] = useState(0);
  const [data, setData] = useState<any>([]);
  const [video, setVideo] = useState(
    "https://www.youtube.com/embed/ldNtfHakuQg"
  );

  const loadData = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useData()
      .getData("cinema.php")
      .then((response: any) => {
        setData(response);
      })
      .catch((err) => {
        console.info(err);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const MudaVideo = (link: any, titulo: any) => {
    setVideo(link);
    setTituloVideo(titulo);
  };

  const AbreCinema = (x: any) => {
    setTitulo(x[0]);
    setDescricao(x[1]);
    setId(x[2]);
    setExtensao(x[3]);
    setCinema(1);
  };

  const FechaCinema = () => {
    setTitulo(0);
    setDescricao(0);
    setId(0);
    setExtensao(0);
    setCinema(0);
  };

  return (
    <>
      {/* 
      <S.OutImgCareer>
        <img src={ImgCareer} />
      </S.OutImgCareer>


      <S.OutImgBgCommercial>
        <S.TextImgCommercial1>MAIS DE 3 DÉCADAS</S.TextImgCommercial1>
        <S.TextImgCommercial2>DE CARREIRA</S.TextImgCommercial2>
      </S.OutImgBgCommercial>

      <S.OutCommercial>
        <div style={{ position: "absolute", marginTop: "-115px" }} id="Cinema"></div>
        <S.OutImgCommercial>
          <img src={ImgCommercia2} />
        </S.OutImgCommercial>
        <S.OutContentCommercial>
          <S.ContentCommercial>
            <S.TextCommercial1>Cinema e tv</S.TextCommercial1>
            <S.TextCommercial2>
              Neste link é possível conferir produções com a participação do cantor Daniel.
            </S.TextCommercial2>
            <S.OutItemsCommercial>
              {data &&
                data.map((data: any, index: React.Key | null | undefined) => ( 
                  <S.TextItemCommercial key={index}  onClick={() => AbreCinema([data.c_titulo, data.c_descricao, data.id_tb_cinema, data.c_extensao1])}>{data.c_titulo}</S.TextItemCommercial>
                ))}
            </S.OutItemsCommercial>
          </S.ContentCommercial>
        </S.OutContentCommercial>
      </S.OutCommercial>

      */}

      {cinema === 1 && (
        <S.BgCommercial2>
          <S.XCommercial onClick={() => FechaCinema()}>X</S.XCommercial>
          <S.ContentCommercial2>
            <S.OutImgCinema>
              <img
                src={`https://danielcorp.com.br/upload/parceiro/${id}a.${extensao}`}
              />
            </S.OutImgCinema>
            <S.LeftCommercial>
              <S.TextLeftCommercial2>{titulo}</S.TextLeftCommercial2>
              <S.TextLeftCommercial3>{descricao}</S.TextLeftCommercial3>
            </S.LeftCommercial>
          </S.ContentCommercial2>
        </S.BgCommercial2>
      )}
      {/* 
      <S.OutEvents>
        <S.OutImgEvents>
          <img src={ImgEventos} />
        </S.OutImgEvents>
        <S.LeftEvents>
          <S.TextEvents1>EVENTOS INTERNACIONAIS</S.TextEvents1>
          <S.TextEvents2>{tituloVideo}</S.TextEvents2>
          <iframe
            src={video}
            width="100%"
            className="IframeEventos"
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          />
          <S.OutButtonsEvents>
            <S.ButtonEvent onClick={() => MudaVideo("https://www.youtube.com/embed/ldNtfHakuQg", "TRILHA SONORA EUA") }>
              TRILHA SONORA EUA
            </S.ButtonEvent>
            <S.ButtonEvent onClick={() => MudaVideo("https://www.youtube.com/embed/ldNtfHakuQgx", "BRAZILIAN DAY NY") }>
              BRAZILIAN DAY NY
            </S.ButtonEvent>
            <S.ButtonEvent onClick={() => MudaVideo("https://www.youtube.com/embed/ldNtfHakuQg", "TOUR EUROPA") }>
              TOUR EUROPA
            </S.ButtonEvent>
          </S.OutButtonsEvents>
        </S.LeftEvents>
      </S.OutEvents>
      */}
    </>
  );
}

export default withRouter(Career);
