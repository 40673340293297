/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ImgDanielShows from "../../Images/ImgDanielShows.png";
import ImgMoreShows from "../../Images/ImgMoreShows.png";
import ImgBgContentShows from "../../Images/BgContentShows.png";
import ImgItemShow from "../../Images/ImgItemShow.jpg";

import ReactHtmlParser from "react-html-parser";

import useData from "../../Utils/useData";

function Shows(props: any) {
  const history = useHistory();
  const [data, setData] = useState<any>([]);
  const [data2, setData2] = useState<any>([]);
  const [show3, setShow3] = useState(0);
  const [titulo, setTitulo] = useState("");
  const [texto, setTexto] = useState("");

  const page = useRef<any>(1);

  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const loadData = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useData()
      .getData(`shows.php?page=${page.current}`)
      .then((response: any) => {
        if (page.current == 1) {
          setData(response);
        } else {
          setData((data: any) => [...data, response]);
        }
      })
      .catch((err) => {
        console.info(err);
      });
  };

  const AbreFotos = (idShow: any) => {
    setShow3(2);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      idTbShow: idShow,
    });

    fetch("https://danielcorp.com.br/app_site/FotosShows.php", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => response.text())
      .then((response: any) => {
        setData2(JSON.parse(response));
        // console.log(JSON.parse(response)[0]);
        // console.log(JSON.parse(response)[0].id_tb_show);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const hash = props.history.location.hash;
    if (hash && document.getElementById(hash.substr(1))) {
      // Check if there is a hash and if an element with that id exists
      document
        .getElementById(hash.substr(1))
        ?.scrollIntoView({ behavior: "smooth" });
    }
    // }, []);
  }, [props.history.location.hash]);

  return (
    <>
      <S.OutShows>
        <S.TopShows>
          <S.TextShows1>SHOWS, COMERCIAIS, FILMES, NOVELAS</S.TextShows1>
          <S.TextShows2>e muito mais!</S.TextShows2>
          <div id="Shows"></div>
        </S.TopShows>
        <S.ContentShows>
          <S.OutImgDaniel>
            <img src={ImgDanielShows} />
          </S.OutImgDaniel>
          <S.RightContentShows>
            <S.ImgContentShows>
              <img src={ImgBgContentShows} />
            </S.ImgContentShows>
            <S.OutRightContent>
              <S.TextShows3>Shows Corporativos</S.TextShows3>
              <S.TextShows4>
                O cantor Daniel tem uma apresentação diferenciada para eventos
                corporativos. Em plena sintonia com sua banda ele traz um show
                com repertório selecionado especialmente para estas ocasiões
                especiais. Consulte sua data.
              </S.TextShows4>
              <S.OutItemsShows>
                {data &&
                  data.map((data: any, index: React.Key | null | undefined) => (
                    <>
                      {data.c_titulo && (
                        <S.TextItemShows
                          onClick={() => {
                            AbreFotos(data.id_tb_show);
                            setTitulo(data.c_titulo);
                            setTexto(data.c_texto);
                          }}
                          key={index}
                        >
                          {data.c_titulo}
                        </S.TextItemShows>
                      )}
                    </>
                  ))}
              </S.OutItemsShows>
              <S.RiskMoreShows />
              <S.OutMoreShows
                onClick={() => {
                  page.current = page.current + 1;
                  loadData();
                }}
              >
                <S.TextMoreShows>mais shows</S.TextMoreShows>
                <S.OutImgMoreShows>
                  <img src={ImgMoreShows} />
                </S.OutImgMoreShows>
              </S.OutMoreShows>
            </S.OutRightContent>
          </S.RightContentShows>
        </S.ContentShows>
      </S.OutShows>

      {show3 !== 0 && (
        <S.BgShows2>
          <S.XShows onClick={() => setShow3(0)}>X</S.XShows>
          <S.OutItemsShows>
            <Slider className="SliderBanner1" {...settings}>
              {data2 &&
                data2.map(
                  (data2: any, index2: React.Key | null | undefined) => (
                    <S.ItemShows key={index2}>
                      <S.LeftItemShows>
                        <S.TextItemShows1>{titulo}</S.TextItemShows1>
                        <S.TextItemShows2>
                          {ReactHtmlParser(texto)}
                        </S.TextItemShows2>
                      </S.LeftItemShows>
                      <S.RightItemFotos
                        style={{
                          backgroundImage:
                            "url('https://danielcorp.com.br/upload/show/" +
                            data2.id_tb_foto +
                            "." +
                            data2.c_extensao +
                            "')",
                        }}
                      />
                    </S.ItemShows>
                  )
                )}
            </Slider>
          </S.OutItemsShows>
        </S.BgShows2>
      )}
    </>
  );
}

export default withRouter(Shows);
