import styled from "styled-components";
// import ImgBgTopPartners from "../../Images/BgTopPartners.jpg";

export const OutContact = styled.section`
  width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 130px;
  padding-bottom: 40px;

  @media (max-width: 1000px) {
    flex-direction: column;
    padding-top: 7vw;
    padding-bottom: 4vw;
  }
`;

export const LeftContact = styled.div`
  width: 50%;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const TextContact1 = styled.div`
  font-weight: bold;
  font-size: 75px;
  text-transform: uppercase;
  line-height: 75px;

  @media (max-width: 1000px) {
    font-size: 50px;
    line-height: 50px;
  }
`;

export const TextContatct2 = styled.div`
  font-weight: 300;
  font-size: 18px;
  margin-top: 40px;
  margin-bottom: 40px;

  @media (max-width: 1270px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const BaixoForm = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  @media (max-width: 1180px) {
    flex-direction: column;
  }

  @media (max-width: 1000px) {
    flex-direction: row;
  }

  @media (max-width: 740px) {
    flex-direction: column;
  }
`;

export const OutRiskContact = styled.div`
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const RightContact = styled.div`
  width: 40%;
  // padding-top: 50px;

  @media (max-width: 1270px) {
    width: 43%;
  }

  @media (max-width: 1000px) {
    width: 100%;
    padding-top: 20px;
  }
`;

export const OutItemContact = styled.div`
  // margin-top: 40px;

  @media (max-width: 1000px) {
    // margin-top: 15px;
  }
`;

export const OutLowBanner = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
`;

export const LeftLowBanner = styled.div`
  width: 100%;
`;

export const OutImageOpus = styled.div`
  width: 150px;
  margin-top: 15px;
  margin-bottom: 15px;

  @media (max-width: 1400px) {
    width: 125px;
  }
`;

export const OutImageOpusResp = styled.div`
  display: none;
`;

export const RightLowBanner = styled.div`
`;

export const TextBanner2 = styled.div`
  font-weight: bold;
  font-size: 20px;
`;

export const TextBanner3 = styled.div`
  font-weight: 300;
  font-size: 18px;

  @media (max-width: 480px) {
    font-size: 14.7px;
  }
`;

export const ItemContact = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  cursor: pointer;
`;

export const OutIconContact = styled.div`
  margin-bottom: -8px;
  width: 28px;

  @media (max-width: 1270px) {
    width: 20px;
  }
`;

export const TextItemContact1 = styled.div`
  font-size: 22px;
  margin-left: 10px;
  width: 88%;

  @media (max-width: 1270px) {
    font-size: 18px;
    width: 85%;
  }

  @media (max-width: 450px) {
    font-size: 14px;
    margin-left: 6px;
  }
`;

export const OutRedesContact = styled.div``;

export const ItemRedesContact = styled.div`
  display: flex;
  border: 2px solid #446fe7;
  width: 250px;
  height: 55px;
  align-items: center;
  justify-content: center;
  border-radius: 31px;
  margin-top: 25px;
  cursor: pointer;

  @media (max-width: 1000px) {
    margin-top: 20px;
    height: 45px;
  }
`;

export const OutIconRedesContact = styled.div`
  margin-bottom: -4px;
  margin-right: 10px;

  @media (max-width: 1000px) {
    margin-bottom: -7px;
    margin-right: 10px;
    width: 31px;
  }
`;

export const TextRedesContact = styled.div`
  font-weight: 900;
  font-size: 18px;
`;
