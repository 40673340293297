/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";

import IconRedes1 from "../../Images/IconRedes1.png";
import IconRedes2 from "../../Images/IconRedes2.png";
import IconRedes3 from "../../Images/IconRedes3.png";
import IconRedes4 from "../../Images/IconRedes4.png";
import ImgInternet from "../../Images/ImgInternet.png";
import ImgLogoMercedes from "../../Images/LogoMercedes.png";

function Internet(props: any) {
  const history = useHistory();
  const [video, setVideo] = useState(
    "https://www.youtube.com/embed/ldNtfHakuQg"
  );

  const MandaFace = () => {
    window.open("https://www.facebook.com/cantorDaniel/", "_blank");
  };

  const MandaYoutube = () => {
    window.open("https://www.youtube.com/danieloficial", "_blank");
  };

  const MandaInsta = () => {
    window.open("https://www.instagram.com/cantordaniel/", "_blank");
  };

  const MandaTwitter = () => {
    window.open("https://twitter.com/cantordaniel", "_blank");
  };

  return (
    <>
      <div id="Internet"></div>
      <S.OutInternet>
        <S.LeftInternet>
          <S.TextInternet1>DIGITAL</S.TextInternet1>
          <S.TextInternet2>
            Ativo nas redes sociais, o cantor Daniel possui mais de 6 milhões de
            seguidores que repercutem tudo aquilo que é divulgado por ele.
            Atualmente o facebook, X e Instagram e youtube são os canais de
            comunicação mais fortes entre o ídolo e seus fãs.
          </S.TextInternet2>
          <S.ContentRedes>
            <S.ItemRedes onClick={MandaInsta}>
              <S.OutImgRedes>
                <img src={IconRedes1} />
              </S.OutImgRedes>
              <S.TextRedes1>Instagram</S.TextRedes1>
            </S.ItemRedes>
            <S.ItemRedes onClick={MandaYoutube}>
              <S.OutImgRedes>
                <img src={IconRedes2} />
              </S.OutImgRedes>
              <S.TextRedes1>Youtube</S.TextRedes1>
            </S.ItemRedes>
            {/* <S.ItemRedes onClick={MandaTwitter}>
              <S.OutImgRedes>
                <img src={IconRedes3} />
              </S.OutImgRedes>
              <S.TextRedes1>X</S.TextRedes1>
            </S.ItemRedes> */}
            <S.ItemRedes onClick={MandaFace}>
              <S.OutImgRedes>
                <img src={IconRedes4} />
              </S.OutImgRedes>
              <S.TextRedes1>Facebook</S.TextRedes1>
            </S.ItemRedes>
          </S.ContentRedes>
        </S.LeftInternet>
        <S.RightInternet>
          <img src={ImgInternet} />
        </S.RightInternet>
      </S.OutInternet>

      {/*
      
     
      <S.OutPartner>
        <S.LeftPartner>
          <iframe
            src={video}
            width="100%"
            className="IframeMercedes"
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          />
          <S.ContenetLeftPartner>
            <S.ItemLeftPartner onClick={() => setVideo('https://www.youtube.com/embed/ldNtfHakuQg')}>Vídeo 1</S.ItemLeftPartner>
            <S.ItemLeftPartner onClick={() => setVideo('https://www.youtube.com/embed/ldNtfHakuQg')}>Vídeo 2</S.ItemLeftPartner>
            <S.ItemLeftPartner onClick={() => setVideo('https://www.youtube.com/embed/ldNtfHakuQg')}>Vídeo 3</S.ItemLeftPartner>
            <S.ItemLeftPartner onClick={() => setVideo('https://www.youtube.com/embed/ldNtfHakuQg')}>Vídeo 4</S.ItemLeftPartner>
          </S.ContenetLeftPartner>
        </S.LeftPartner>
        <S.RightPartner>
          <S.LogoMercedes><img src={ImgLogoMercedes} /></S.LogoMercedes>
          <S.TextPartner1>
            NOVA PARCEIRA DE ESTRADA DO CANTOR DANIEL É A MERCEDES-BENZ
          </S.TextPartner1>      
          <S.TextPartner2>
            O cantor Daniel revelou que os vídeos divulgados em suas páginas oficiais no Facebook e Instagram, 
            despertando a curiosidade do público em torno do seu novo parceiro, são parte da campanha de lançamento dos seus 
            reais parceiros de estrada: na cidade, o ágil e potente caminhão Mercedes-Benz Accelo, e, na estrada, o versátil e robusto Mercedes-Benz Atego.
          </S.TextPartner2>         
           <S.TextPartner2>
            Com o slogan “Mercedes-Benz Caminhões. Os seus melhores parceiros de estrada”, a campanha causou alvoroço na internet, principalmente entre os fãs do artista.
          </S.TextPartner2>
        </S.RightPartner>
      </S.OutPartner>
       */}
    </>
  );
}

export default withRouter(Internet);
