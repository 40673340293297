import React, { useEffect, useState } from "react";
import * as S from "./estilos";
import useData from "../../Utils/useData";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import IconLab from "../../Images/IconLab.png";

function Footer() {
  const [data, setData] = useState<any>([]);
  const loadData = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useData()
      .getData("ParceirosRodape.php")
      .then((response: any) => {
        setData(response);
      })
      .catch((err) => {
        console.info(err);
      });
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 5,

    responsive: [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },

      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const MandaLab = () => {
    window.open("http://lab3.tv/", "_blank");
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <S.OutPartner>
        <S.TopPartner>
          <S.RiskPartner />
          <S.TextPartner>parceiros</S.TextPartner>
          <S.RiskPartner />
        </S.TopPartner>
        <S.ContentPartner>
          <S.OutItensContentPartner>
            {data &&
              data.map((data: any, index: React.Key | null | undefined) => (
                <>
                  <S.OutImgPartner
                    onClick={() =>
                      data.c_link !== "" ? window.open(data.c_link) : null
                    }
                    key={index}
                  >
                    <img
                      src={
                        "https://danielcorp.com.br/upload/ParceiroRodape/" +
                        data.id_tb_parceiro_rodape +
                        "a." +
                        data.c_extensao1
                      }
                      width="100px"
                    />
                  </S.OutImgPartner>
                </>
              ))}
          </S.OutItensContentPartner>
        </S.ContentPartner>
      </S.OutPartner>

      <S.OutLab onClick={() => MandaLab()}>
        <S.OutImgLab>
          <img src={IconLab} />
        </S.OutImgLab>
      </S.OutLab>
    </>
  );
}

export default Footer;
